<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="amount" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('amount')">
                            <b-form-input
                                type="number"
                                min="0" step=".01"
                                v-check-min-max-value
                                v-model="form.amount"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox
                                :set-active="true"
                                :validate-error="errors[0]"
                                v-model="form.academic_year">
                            </academic-years-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('period')">
                            <semester-type-selectbox v-model="form.semester"
                                                     :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-button @click="updateForm"
                      :disabled="formLoading"
                      type="button"
                      variant="primary"
                      class="btn-lg mr-2">
                {{ $t('save').toUpperCase() }}
            </b-button>
        </ValidationObserver>
    </div>
</template>

<script>
//Component
import SemesterTypeSelectbox from "@/components/interactive-fields/SemesterTypeSelectbox.vue";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox.vue";

//Other
import {ValidationProvider, ValidationObserver} from "vee-validate"

//Service
import StaffService from "@/services/StaffService";

export default {
    components: {
        AcademicYearsSelectbox,
        SemesterTypeSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formLoading: false,
            form: {
                amount: null,
                academic_year: null,
                semester: null,
            }
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            this.formLoading = true;
            StaffService.get(id)
                .then((response) => {
                    this.form = response.data.data;
                    this.formLoading = false;
                })
                .catch((error) => {
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                });
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                this.formLoading = true;
                StaffService.update(this.formId, this.form)
                    .then((response) => {
                        this.formLoading = false;
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.$emit("adjunctPayFormSuccess")
                    })
                    .catch((error) => {
                        this.showErrors(error, this.$refs.formModalValidate)
                    })
            }
        }
    }
}
</script>
